import React from "react";
import Head from "next/head";
import Script from "next/script";
import "./../i18n/config";
import AppBarTop from "../components/AppBar/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Footer from "../components/Footer/Footer";
import Providers from "./_providers";
import Stores from "./_stores";
import { Stack } from "@mui/material";
import { grey, EDTypography, error } from "@trailblazer-game/ed-webkit";

// Use require instead of import, and order matters
require("../styles/globals.css");
require("@trailblazer-game/ed-webkit/lib/styles.css");

function App({ Component, pageProps }: any) {
  // Default dynamic meta tags. Dynamic for /dragon/[MINT_ID]!
  const metaTags = {
    title: Component.metaData?.title || "Eternal Dragons Universe",
    image:
      Component.metaData?.image ||
      "https://cdn.eternaldragons.com/assets/Splashart2CleanV4b_logo.png",
    description:
      Component.metaData?.description ||
      "Join the epic saga of Eternal Dragons! A new and immersive web3 gaming universe. Chapter 1 Alpha now open!",
  };

  if (pageProps.dynamicMetaTags) {
    metaTags.title = pageProps.dynamicMetaTags.title;
    metaTags.image = pageProps.dynamicMetaTags.image;
    metaTags.description = pageProps.dynamicMetaTags.description;
  }

  return (
    <>
      <Head>
        <title>{metaTags.title}</title>
        <meta name="og:title" property="og:title" content={metaTags.title} />
        <meta name="twitter:title" content={metaTags.title} />

        <meta name="image" property="og:image" content={metaTags.image} />
        <meta name="twitter:image" content={metaTags.image} />

        <meta name="description" content={metaTags.description} />
        <meta
          name="og:description"
          property="og:description"
          content={metaTags.description}
        />
        <meta name="twitter:description" content={metaTags.description} />
      </Head>

      <Script
        id="termly"
        strategy="beforeInteractive"
        type="text/javascript"
        src="https://app.termly.io/embed.min.js"
        data-auto-block="on"
        data-website-uuid="bd4fc798-5132-4745-9b2f-61a0938f7260"
      />

      <Script id="google-tag-manager" strategy="lazyOnload">
        {`
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=${process.env.NEXT_PUBLIC_GMTAG_ANO_AUTH}&gtm_preview=${process.env.NEXT_PUBLIC_GMTAG_ANO_PREVIEW}&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GMTAG_ANO_ID}');
            `}
      </Script>
      <Providers>
        <Stores />
        <Box className={"website-wrapper"}>
          <CssBaseline />
          <Component {...pageProps} />
          <Footer />
        </Box>
      </Providers>
    </>
  );
}

export default App;
